import React from 'react'
import { StyledConstactAdressItem, StyledContactAdress, StyledContactHeading, StyledContactInfo, StyledContactInfoHolder, StyledContactSubheading } from './ContactInfo.styles'
import Icon from '../Icon/Icon'
import { theme } from '../../theme/theme'
import contactIcon from '../../assets/icons/contact-icon.svg'
import LinkButton from '../UI/LinkButton/LinkButton'
import { useTranslation } from 'react-i18next'

const ContactInfo = () => {
  const {t} = useTranslation();
  return (
    <StyledContactInfo>
      <StyledContactInfoHolder>
        <Icon icon={contactIcon} color={theme.colors.blue} height={40} width={40} />
        <StyledContactHeading>{t('contact-page_contact-title')}</StyledContactHeading>
        <StyledContactSubheading>{t('contact-page_contact-subtitle')}</StyledContactSubheading>
        <StyledContactAdress>
          <StyledConstactAdressItem>{t('contact-page_contact-adress')}</StyledConstactAdressItem>
          <StyledConstactAdressItem>office@klaw.rs</StyledConstactAdressItem>
          <StyledConstactAdressItem>+381 (0) 21 3035849</StyledConstactAdressItem>
          <StyledConstactAdressItem>+381 (0) 64 2538315</StyledConstactAdressItem>
        </StyledContactAdress>
        <LinkButton linkTo='mailto:goran.korac@klaw.rs' targetBlank regularLink  title='contact us' text={t('contact-page_contact-link')} />
      </StyledContactInfoHolder>
    </StyledContactInfo>
  )
}

export default ContactInfo