import React from 'react'
import Header from '../../components/shared/Header/Header'
import Footer from '../../components/shared/Footer/Footer'
import Wrapper from '../../components/UI/Wrapper/Wrapper'
import PersonItem from '../../components/PersonItem/PersonItem'
import { TeamPageDesc, TeamPageHeading, TeamPageHolder, TeamPageList } from './TeamPage.styles'
import { people } from '../../utils/Data'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next'

const TeamPage = () => {
  const {t} = useTranslation();
  return (
    <>
    <Helmet>
      <title>{t('team-page_title')}</title>
      <meta name="description" content={t('team-page_desc')}/>
      <meta name="author" content="Petar Purać" />
      <link rel="canonical" href="https://klaw.rs/team" />
    </Helmet>
    <Header></Header>
    <main className='main'>
      <Wrapper>
        <TeamPageHolder>
            <TeamPageHeading>{t('team-page_team-heading')}</TeamPageHeading>
            <TeamPageDesc>{t('team-page_team-desc')}</TeamPageDesc>
            <TeamPageList>
              {people.map((person)=> (
                <PersonItem key={person.name} person={person}/>
                ))}
            </TeamPageList>
        </TeamPageHolder>
      </Wrapper>
    </main>
    <Footer></Footer>
  </>
  )
}

export default TeamPage