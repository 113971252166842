import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const StyledContactMap = styled.div`
    width: 66.6667%;
    
    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }

    @media(max-width:${theme.breakpoints.md}){
        width: 100%;
        iframe{

            height: 300px;
        }
    }
`