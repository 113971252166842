const theme = {
    fontSizes: {
        h1: '10px',
        2: '12px',
        3: '14px',
        4: '16px',
        5: '20px',
    },
    breakpoints: {
        xs: '576px',
        sm: '768px',
        md: '992px',
        lg: '1200px'
    },
    colors: {
        blue: '#002D4B',
        red: '#FF4C5A',
        white: '#fff',
        black : '#000',
        lightGrey: '#fafafa',
        grey: '#E1E3E8'
    },
  }
  
  export { theme }