import React from 'react'
import Icon from '../../Icon/Icon'
import { theme } from '../../../theme/theme'
import chevronRight from '../../../assets/icons/arrow-right-solid.svg'
import { StyledHashLinkButton, StyledLinkButton, StyledRouterLinkButton } from './LinkButton.style'

const LinkButton = ({linkTo, title, text, routerLink, iswhite, hashLink, regularLink,targetBlank}) => {
  return (
    <>
    {regularLink && (
      <StyledLinkButton href={linkTo} title={title} className={`${iswhite ? 'link--white' : ''}`}  target={targetBlank ? '_blank' : ''}>
      <span>
        {text}
      </span>
        <Icon className='link-icon' icon={chevronRight} color={iswhite ? theme.colors.white : theme.colors.blue} height={15} width={15} />
    </StyledLinkButton>
    )} 
      {routerLink && <StyledRouterLinkButton to={linkTo} title={title} className={`${iswhite ? 'link--white' : ''}`}>
      <span>
        {text}
      </span>
        <Icon className='link-icon' icon={chevronRight} color={iswhite ? theme.colors.white : theme.colors.blue} height={15} width={15} />
    </StyledRouterLinkButton>}  
        
      {hashLink && <StyledHashLinkButton to={linkTo} title={title} className={`${iswhite ? 'link--white' : ''}`}>
      <span>
        {text}
      </span>
        <Icon className='link-icon' icon={chevronRight} color={iswhite ? theme.colors.white : theme.colors.blue} height={15} width={15} />
    </StyledHashLinkButton>}
    </>
  )
}

export default LinkButton