import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const StyledAboutUs = styled.div`
    width: 33.33%;

    @media (max-width: ${theme.breakpoints.lg}) {
        width: 50%;
    }

    @media (max-width: ${theme.breakpoints.md}) {
        width: 100%;
        padding: 0;
    }   
`
export const StyledAboutHolder = styled.div`
    height: 100%;
    border: 2px solid ${theme.colors.blue};
    padding: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`

export const StyledAboutHeader = styled.h2`
    margin-bottom: 40px;

    @media (max-width: ${theme.breakpoints.sm}) {
        margin-bottom: 20px;
    }
`

export const StyledAboutDesc = styled.p`
    margin-bottom: 30px;
    text-align: center !important;

    @media (max-width: ${theme.breakpoints.md}) {
        text-align: left !important;

    }
    @media (max-width: ${theme.breakpoints.sm}) {
        margin-bottom: 20px;
    }
`