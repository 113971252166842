import React from 'react'
import Wrapper from '../../UI/Wrapper/Wrapper'
import FooterLogo from './FooterLogo/FooterLogo'
import { StyledFooter, StyledFooterWrap } from './Footer.styles'
import FooterInfo from './FooterInfo/FooterInfo'

const Footer = () => {
  return (
    <StyledFooter>
      <Wrapper>
        <StyledFooterWrap>
          <FooterLogo/>
          <FooterInfo/>
        </StyledFooterWrap>
      </Wrapper>
    </StyledFooter>
  )
}

export default Footer