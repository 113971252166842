import React from 'react'
import Header from '../../components/shared/Header/Header'
import Footer from '../../components/shared/Footer/Footer'
import Wrapper from '../../components/UI/Wrapper/Wrapper'
import { expertises } from '../../utils/Data'
import { ExpertiseHolder, ExpertiseHover, ExpertisesContainer, ExpertisesText, ExpertisesTop, ExpertisesTopHeading, StyledExpertiseItem, StyledExpertiseItemHeading } from './ExpertisesPage.styles'
import LinkButton from '../../components/UI/LinkButton/LinkButton'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next'

const ExpertisesPage = () => {
  const {t} = useTranslation();
  return (
    <>
    <Helmet>
      <title>{t('expertises-page_title')}</title>
      <meta name="description" content={t('expertises-page_desc')}/>
      <meta name="author" content="Petar Purać" />
      <link rel="canonical" href="https://klaw.rs/expertises" />
    </Helmet>
 
    
    <Header></Header>
    <main className='main'>
      <Wrapper>
        <ExpertiseHolder>
            <ExpertisesTop>
              <ExpertisesTopHeading>{t('expertise-page_exp-title')}</ExpertisesTopHeading>
              <ExpertisesText>{t('expertise-page_exp-desc')}</ExpertisesText>
            </ExpertisesTop>
          <ExpertisesContainer>
            {expertises.map(expertise => (
              <StyledExpertiseItem key={expertise.name}>
                <ExpertiseHover>
                  <LinkButton text={t('learn-more')} linkTo={`/expertises/${expertise.routeName}`} routerLink iswhite={true} title='Learn More About Expertise'/>
                </ExpertiseHover>
                <StyledExpertiseItemHeading>{t(`${expertise.name}`)}</StyledExpertiseItemHeading>
              </StyledExpertiseItem>
            ))}
          </ExpertisesContainer>
        </ExpertiseHolder>
      </Wrapper>
    </main>
    <Footer></Footer>
  </>
  )
}


export default ExpertisesPage