import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const StyledAPA = styled.section`
    margin-bottom: 80px;
    @media(max-width: ${theme.breakpoints.md}){
        margin-bottom: 40px;
    }
    @media(max-width: ${theme.breakpoints.sm}){
        margin-bottom: 30px;
    }
`

export const StyledAPAHolder = styled.div`
    width: 100%;
    display:flex;
    align-items: stretch;
    gap: 20px;
    @media(max-width: ${theme.breakpoints.md}){
        flex-direction: column;
    }
`

export const StyledAPALeft = styled.div`
    background: #E9E5E3;
    width: 66.66%;
    padding: 60px 80px;
    @media(max-width: ${theme.breakpoints.md}){
        width: 100%;
        padding: 30px 40px;
    }
`
export const StyledAPARight = styled.div`
    width: 33.33%;
    border: 2px solid ${theme.colors.blue};
    padding: 60px 80px;
    text-align: center;

    @media(max-width: ${theme.breakpoints.md}){
        width: 100%;
        padding: 30px 40px;
    }
`

export const StyledAPAHeading = styled.h2`
    margin-bottom: 40px;
`

export const StyledAPAdesc = styled.p`
    margin-bottom: 15px;
`

export const StyledAPARightHeading = styled.h3`
    position: relative;
    margin-bottom: 40px;

    &::after {
        content: '';
        position: absolute;
        bottom: -15px;
        left: 50%;
        width: 20%;
        height: 4px;
        border-radius: 10px;
        background: ${theme.colors.red};
        transform: translateX(-50%);
    }
`

export const StyledAPAIconHolder = styled.div`
    min-height: calc(100% - 60px);
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;

    @media(max-width: ${theme.breakpoints.md}){
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }
    @media(max-width: ${theme.breakpoints.xs}){
        flex-direction: column;
    }
`