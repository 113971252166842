import { Route, Routes } from 'react-router-dom';
import './App.css';
import { StyledApp } from './App.styles';
import HomePage from './pages/HomePage/HomePage';
import TeamPage from './pages/TeamPage/TeamPage';
import TeamMemberPage from './pages/TeamMemberPage/TeamMemberPage';
import ExpertisesPage from './pages/ExpertisesPage/ExpertisesPage';
import ExpertisePage from './pages/ExpertisePage/ExpertisePage';
import IndustriesPage from './pages/IndustriesPage/IndustriesPage';
import IndustryPage from './pages/IndustryPage/IndustryPage';
import ContactPage from './pages/ContactPage/ContactPage';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import AboutPage from './pages/AboutPage/AboutPage';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { AnimationLayout } from './components/AnimationLayout/AnimationLayout';

function App() {
  return (
    <StyledApp className="App">
      <ScrollToTop>
        <Routes>
        <Route element={<AnimationLayout />}>
          <Route path='/' element={<HomePage/>} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/team' element={<TeamPage/>} />
          <Route path='/team/:url' element={<TeamMemberPage/>} />
          <Route path='/expertises' element={<ExpertisesPage/>} />
          <Route path='/expertises/:name' element={<ExpertisePage/>} />
          <Route path='/industries' element={<IndustriesPage/>} />
          <Route path='/industries/:name' element={<IndustryPage/>} />
          <Route path='/contact' element={<ContactPage/>} />
          <Route path="/404" element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>
        </Routes>
      </ScrollToTop>
    </StyledApp>
  );
}

export default App;
