import React from 'react'
import Header from '../../components/shared/Header/Header'
import Footer from '../../components/shared/Footer/Footer'
import HomeBanner from '../../components/HomeBanner/HomeBanner'
import AboutSection from '../../components/AboutSection/AboutSection'
import TeamSection from '../../components/TeamSection/TeamSection'
import ExpertisesSection from '../../components/ExpertisesSection/ExpertisesSection'
import IndustriesSection from '../../components/IndustriesSection/IndustriesSection'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

const HomePage = () => {
  const {t} = useTranslation();
  return (
    <>
    <Helmet>
      <title>{t('home-page_title')}</title>
      <meta name="description" content={t('home-page_desc')}/>
      <meta name="author" content="Petar Purać" />
      <link rel="canonical" href="https://klaw.rs/" />
    </Helmet>
    <>
      <Header></Header>
      <main className='main'>
        <HomeBanner/>
        <AboutSection/>
        <TeamSection/>
        <ExpertisesSection/>
        <IndustriesSection/>
      </main>
      <Footer></Footer>
    </>
    </>
  )
}

export default HomePage