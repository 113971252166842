import styled from 'styled-components';
import { theme } from './theme/theme';

export const StyledApp = styled.div`
  h1 {
    color: ${theme.colors.blue};
    font-size: 48px;
    line-height: 56px;

    @media(max-width: ${theme.breakpoints.md}) {
        font-size : 36px;
        line-height: 44px;
    }

    @media(max-width: ${theme.breakpoints.sm}) {
        font-size : 36px;
        line-height: 44px;
    }
  }
  h2 {
    color: ${theme.colors.blue};
    font-size: 36px;
    line-height: 44px;

    @media(max-width: ${theme.breakpoints.md}) {
        font-size : 28px;
        line-height: 36px;
    }

    @media(max-width: ${theme.breakpoints.sm}) {
        font-size : 24px;
        line-height: 32px;
    }
  }
  h3 {
    color: ${theme.colors.blue};
    font-size: 24px;
    line-height: 32px;

    @media(max-width: ${theme.breakpoints.sm}) {
        font-size : 20px;
        line-height: 28px;
    }
  }
  h4 {
    color: ${theme.colors.blue};
    font-size: 20px;
    line-height: 28px;

    @media(max-width: ${theme.breakpoints.sm}) {
        font-size : 18px;
        line-height: 24px;
    }
  }
  h5 {
    color: ${theme.colors.blue};
    font-size: 18px;
    line-height: 24px;

    @media(max-width: ${theme.breakpoints.sm}) {
        font-size : 16px;
        line-height: 20px;
    }
  }
  h6 {
    color: ${theme.colors.blue};
    font-size: 16px;
    line-height: 20px;

    @media(max-width: ${theme.breakpoints.sm}) {
        font-size : 16px;
        line-height: 18px;
    }
  }
  p {
    color: ${theme.colors.blue};
    font-size: 18px;
    line-height: 150%;

    @media(max-width: ${theme.breakpoints.md}) {
        font-size : 16px;
    }
  }
`;