import React from 'react'
import Header from '../../components/shared/Header/Header'
import Footer from '../../components/shared/Footer/Footer'
import Wrapper from '../../components/UI/Wrapper/Wrapper'
import { ExpertiseHolder, ExpertiseHover, ExpertisesContainer, ExpertisesText, ExpertisesTop, ExpertisesTopHeading, StyledExpertiseItem, StyledExpertiseItemHeading } from '../ExpertisesPage/ExpertisesPage.styles'
import LinkButton from '../../components/UI/LinkButton/LinkButton'
import { industries } from '../../utils/Data'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next'


const IndustriesPage = () => {
  const {t} = useTranslation();
  return (
    <>
    <Helmet>
      <title>{t('industries-page_title')}</title>
      <meta name="description" content={t('industries-page_desc')}/>
      <meta name="author" content="Petar Purać" />
      <link rel="canonical" href="https://klaw.rs/industries" />
    </Helmet>
    <div>
    <Header></Header>
    <main className='main'>
    <Wrapper>
        <ExpertiseHolder>
            <ExpertisesTop>
              <ExpertisesTopHeading>{t('industries-page_ind-title')}</ExpertisesTopHeading>
              <ExpertisesText>{t('industries-page_ind-desc')}</ExpertisesText>
            </ExpertisesTop>
          <ExpertisesContainer>
            {industries.map(industry => (
              <StyledExpertiseItem key={industry.name}>
                <ExpertiseHover>
                  <LinkButton text={t('learn-more')} linkTo={`/industries/${industry.routeName}`} routerLink iswhite={true} title='Read More about Industry'/>
                </ExpertiseHover>
                <StyledExpertiseItemHeading>{t(`${industry.name}`)}</StyledExpertiseItemHeading>
              </StyledExpertiseItem>
            ))}
          </ExpertisesContainer>
        </ExpertiseHolder>
      </Wrapper>
    </main>
    <Footer></Footer>
  </div>
  </>
  )
}

export default IndustriesPage