import React from 'react'
import Header from '../../components/shared/Header/Header'
import Footer from '../../components/shared/Footer/Footer'
import AboutPageAbout from '../../components/AboutPageAbout/AboutPageAbout'
import AboutPageInternational from '../../components/AboutPageInternational/AboutPageInternational'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next'

const AboutPage = () => {
  const {t} = useTranslation();
  return (
    <>
    <Helmet>
      <title>{t('about-page_title')}</title>
      <meta name="description" content={t('about-page_desc')}/>
      <meta name="author" content="Petar Purać" />
      <link rel="canonical" href="https://klaw.rs/about" />
    </Helmet>
    <Header></Header>
    <main className='main'>
        <AboutPageAbout />
        <AboutPageInternational />
    </main>
    <Footer></Footer>
  </>
  )
}

export default AboutPage