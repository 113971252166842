import styled from 'styled-components';
import { theme } from '../../theme/theme';
import { Link } from 'react-router-dom';

export const StyledIndustriesSection = styled.section`
    margin-bottom: 60px;
    @media (max-width: ${theme.breakpoints.md}) {
        margin-bottom: 40px;
    }
`

export const IndustriesSectionHolder = styled.div`
    width: 100%;
    position: relative;
    padding: 60px 40px;
    display: flex;
    justify-content: space-between;

    @media (max-width: ${theme.breakpoints.md}) {
        flex-direction: column;
        gap: 40px;
    }

     @media (max-width: ${theme.breakpoints.sm}) {
        padding: 40px;
    }
`

export const IndustriesSectionImg = styled.img`
    width: 100%;
    height: 100% ;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
`

export const IndustriesTop = styled.div`
    width: 60%;

    @media (max-width: ${theme.breakpoints.md}) {
       width: 100%;
    }
`

export const IndustriesTopHeading = styled.h2`
    color: ${theme.colors.white} !important;
    margin-bottom: 40px;

     @media (max-width: ${theme.breakpoints.sm}) {
        margin-bottom: 20px;
    }
`

export const IndustriesTopDesc = styled.p`
    color: ${theme.colors.white} !important;
    margin-bottom: 30px;

    @media (max-width: ${theme.breakpoints.sm}) {
        margin-bottom: 20px;
    }
`

export const IndustriesBot = styled.div`
    width: 30%;

    @media (max-width: ${theme.breakpoints.md}) {
       width: 100%;
    }
`

export const IndustriesBotList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align:left;
    `

export const IndustriesListItem = styled.li`
    text-align:left;
    width: fit-content;
    list-style-type: none;
    margin-bottom: 25px;
`

export const IndustriesListLink = styled(Link)`
    color: ${theme.colors.white};
    text-decoration: none;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    position: relative;
    padding: 5px;

    &::before {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 5px;
        width: calc(100% - 5px);
        height: 2px;
        background: ${theme.colors.white};
        transition: height .6s ease;
        z-index: -1;
    }
    
    &:hover {
        color: ${theme.colors.blue};
        &::before {
            height: calc(100% + 7px);
        }
    }

    @media (max-width: ${theme.breakpoints.md}) {
       font-size: 16px;
    }
`