import React from 'react'
import { StyledNavItem, StyledNavLink } from './NavItem.styles'
import { useLocation } from 'react-router-dom';

const NavItem = (item, index, onClick, activeItemm, passActiveItem) => {
  const location = useLocation();

  return (
    <StyledNavItem>
        <StyledNavLink to={item.linkTo} title={item.title} className={location.pathname === item.linkTo || location.pathname.includes(item.linkTo + '/') ? 'active' : ''}>
            {item.name}
        </StyledNavLink>
    </StyledNavItem>
  )
}

export default NavItem