import React from 'react'
import Header from '../../components/shared/Header/Header'
import Footer from '../../components/shared/Footer/Footer'
import { useNavigate, useParams } from 'react-router-dom'
import { expertises } from '../../utils/Data'
import Wrapper from '../../components/UI/Wrapper/Wrapper'
import { StlyedListTitle, StyledExpDesc, StyledExpImg, StyledExpListItem, StyledExpText, StyledExpTitle, StyledExpWrap } from './ExpertisePage.styles'
// import { expertises } from '../../components/ExpertisesSection/ExpertisesSection'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next'


const ExpertisePage = () => {
  const {t} = useTranslation();
  const {name} = useParams();
  const [exp] = expertises.filter(exp => exp.routeName === name);
  const navigate = useNavigate();
  if(!exp) {
    navigate("/error")
  }
  return (
    <>
  <Helmet>
      <title>{t(`${exp?.name}`)} | {`${t('expertise-page_title')}` }</title>
      <meta name="description" content={`${t(`${exp?.name}`)} | ${t('expertise-page_desc')} ${t(`${exp?.name}`)}`} />
      <meta name="author" content="Petar Purać" />
      <link rel="canonical" href={`https://klaw.rs/expertise/${name}`} />
    </Helmet>
    <div>
    <Header></Header>
    <main className='main'>
        <Wrapper>
          <StyledExpWrap>
            <StyledExpText>
              <StyledExpTitle>{t(`${exp?.name}`)}</StyledExpTitle>
              <div>
              <ul>
                {exp?.list?.map(item => (
                  <StyledExpListItem>
                  {t(item)}
                </StyledExpListItem>))}
              </ul>
              </div>
            </StyledExpText>
            <StyledExpImg>
              <img src={exp?.img} alt={exp?.imgAlt} title='image that fits expertise'/>
            </StyledExpImg>
          </StyledExpWrap>
        </Wrapper>
      </main>
    <Footer></Footer>
  </div>
  </>
  )
}

export default ExpertisePage