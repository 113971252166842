import React from 'react'
import { StyledCardHolder, StyledPersonImg, StyledPersonImgHolder, StyledPersonItem, StyledPersonLink } from './PersonItem.styles'
import { useTranslation } from 'react-i18next';

const PersonItem = ({person}) => {
    const {t} = useTranslation();
  return (
             
        <StyledPersonItem>
            <StyledPersonLink to={`/team/${person.url}`}>
                <StyledPersonImgHolder>
                    <StyledPersonImg src={person.image} alt={`${person.name} ${person.lastName}`} title='image of our team member'/>
                    <div className="img-overlay"></div>
                    <StyledCardHolder>
                        <h2>{person.name} {person.lastName}</h2>
                        <h4>{t(person.role)}</h4>
                    </StyledCardHolder>
                </StyledPersonImgHolder>
            </StyledPersonLink>
        </StyledPersonItem>
  )
}

export default PersonItem