import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const TeamPageHolder = styled.section`
    margin: 40px 0;
    border: 2px solid ${theme.colors.blue};
    padding: 40px;
    text-align: center;

     @media(max-width: ${theme.breakpoints.md}){
        margin: 20px 0;
    }
`

export const TeamPageHeading = styled.h1`
    margin-bottom: 30px;
`
export const TeamPageHeadingSec = styled.h2`
    margin-bottom: 20px;
`
export const TeamPageDesc = styled.p`
    max-width: 80%;
    margin: 0 auto;
    margin-bottom: 60px;
    @media(max-width: ${theme.breakpoints.md}){
        margin-bottom: 40px;
    }
`
export const TeamPageList = styled.ul`
    display: flex;
    gap: 30px;

    @media(max-width: ${theme.breakpoints.md}){
        flex-direction: column;
    }
`