import React from 'react'
import ContactModule from '../../components/ContactModule/ContactModule'
import Header from '../../components/shared/Header/Header'
import Footer from '../../components/shared/Footer/Footer'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const ContactPage = () => {
  const {t} = useTranslation();
  return (
    <>
    <Helmet>
      <title>{t('contact-page_title')}</title>
      <meta name="description" content={t('contact-page_desc')}/>
      <meta name="author" content="Petar Purać" />
      <link rel="canonical" href="https://klaw.rs/contact" />
    </Helmet>
    <div>
      <Header></Header>
      <main className='main'>
        <ContactModule />
      </main>
      <Footer></Footer>
    </div>
    </>
  )
}

export default ContactPage