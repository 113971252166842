import React from 'react'
import Wrapper from '../UI/Wrapper/Wrapper'
import LinkButton from '../UI/LinkButton/LinkButton'
import { APInternationalLeft, AboutInternationalDesc, AboutInternationalHeading, AboutInternationalHolder, AboutInternationalImage, AboutInternationalSection, AboutInternationalText } from './AboutPageInternational.styles'
import internationalImg from '../../assets/images/international-section.webp'
import { useTranslation } from 'react-i18next'

const AboutPageInternational = () => {
    const {t} = useTranslation();
  return (
    <AboutInternationalSection>
        <Wrapper>
            <AboutInternationalHolder>
                <APInternationalLeft>
                    <AboutInternationalImage src={internationalImg} alt="international symbol" title="international globe symbol for international desk section"/>
                    <div className='img-overlay'></div>
                </APInternationalLeft>
                <AboutInternationalText>
                    <AboutInternationalHeading>{t('about-page_inter-title')}</AboutInternationalHeading>
                    <AboutInternationalDesc>{t('about-page_inter-desc')}</AboutInternationalDesc>
                    <LinkButton linkTo='/team' hashLink iswhite={false} text={t('about-page_inter-link')} title='Learn more about our team'></LinkButton>
                </AboutInternationalText>
            </AboutInternationalHolder>
        </Wrapper>
    </AboutInternationalSection>
  )
}

export default AboutPageInternational

