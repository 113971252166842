import React from 'react'
import Icon from '../Icon/Icon'
import { theme } from '../../theme/theme'
import { StyledTextIcon } from './TextIcon.styles'

const TextIcon = ({icon, text}) => {
  return (
    <StyledTextIcon>
        <Icon icon={icon} color={theme.colors.blue} height={80} width={80} />
        <h4>{text}</h4>
    </StyledTextIcon>
  )
}

export default TextIcon