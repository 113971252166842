import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const StyledContactInfo = styled.div`
    width: 33.333%;
    background: ${theme.colors.lightGrey};
    border: 2px solid ${theme.colors.blue};
    padding: 30px 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width:${theme.breakpoints.md}){
        width: 100%;
        align-items: flex-start;
    }
`

export const StyledContactHeading = styled.h2`
    margin: 15px 0 10px;
`

export const StyledContactSubheading = styled.h3`
    margin-bottom: 20px;
`

export const StyledContactAdress = styled.address`
     margin-bottom:30px;
`

export const StyledConstactAdressItem = styled.p`
    color: ${theme.colors.blue};
    margin-bottom: 10px;
`

export const StyledContactInfoHolder = styled.div`
    max-height: 100%;
`