import React from 'react'
import { StyledIcon } from './Icon.styles'
import { ReactSVG } from 'react-svg'


const Icon = ({color, width, height, icon, className}) => {
  return (

    <StyledIcon width={width} height={height} color={color} className={className}>
           <ReactSVG className='icon' src={icon}/>
    </StyledIcon>
  )
}

export default Icon