import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const StyledContactWrapper = styled.div`
    display: flex;
    width:100%;
    gap: 20px;
    align-items: stretch;
    padding: 40px 0;

    @media(max-width:${theme.breakpoints.md}){
        flex-direction: column;
        height: auto;
    }
`;

export const StyledContactModule = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    
    @media(min-width: ${theme.breakpoints.md}) {
        max-height: calc(100vh - 251.85px); */
    overflow-y: scroll;
    height: calc(100vh - 251.85px);
    }
`