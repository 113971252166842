import styled from 'styled-components';
import { theme } from '../../../../theme/theme';

export const StyledFooterInfo = styled.div`
    display:flex;
    gap: 20px;
    color: #fff;

    @media(max-width: ${theme.breakpoints.md}){
        width: 100%;
        flex-wrap: wrap;
        div {
            width: calc(50% - 20px);
        }
    }

    @media(max-width: ${theme.breakpoints.xs}){
        div {
            width: 100%;
        }
    }
`;