import bankingImg from '../assets/images/banking.webp'
import commercialImg from '../assets/images/commercial.webp'
import competitionImg from '../assets/images/competition.webp'
import complianceImg from '../assets/images/compliance.webp'
import corporateImg from '../assets/images/corporate.webp'
import disputeImg from '../assets/images/dispute.webp'
import employmentImg from '../assets/images/employment.webp'
import intellectualImg from '../assets/images/intellectual.webp'
import realEstateImg from '../assets/images/realEstate.webp'
import goranImg from '../assets/images/goran-resize.webp'
import teodoraImg from '../assets/images/teodora-resize.webp'
import milanaImg from '../assets/images/milana-resize.webp'
import agroImg from '../assets/images/agro.webp'
import constructionImg from '../assets/images/construction.webp'
import sportImg from '../assets/images/sport.webp'
import hospitalityImg from '../assets/images/hospitality.webp'
import codeImg from '../assets/images/it.webp'

export const navigationLinks = [
    {
        id: 1,
        name : 'home-navlink',
        linkTo : '/',
        title: 'Home page link',
        isActive: true,
    },
    {
        id: 2,
        name : 'about-navlink',
        linkTo : '/about',
        title: 'About us page link',
        isActive: false
    },
    {
        id: 3,
        name : 'team-navlink',
        linkTo : '/team',
        title: 'Team page link',
        isActive: false
    },
    {
        id: 4,
        name : 'expertise-navlink',
        linkTo : '/expertises',
        title: 'Expertise page link',
        isActive: false
    },
    {
        id: 5,
        name : 'industries-navlink',
        linkTo : '/industries',
        title: 'Industries page link',
        isActive: false
    },
    {
        id: 6,
        name : 'contact-navlink',
        linkTo : '/contact',
        title: 'Contact page link',
        isActive: false
    },
]

export const expertises = [
    {
        name: "commercial_law",
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, minus!',
        routeName: "commercial-law",
        list: ['exp-comm-1','exp-comm-2','exp-comm-3','exp-comm-4','exp-comm-5','exp-comm-6',],
        listTitle: 'Our services include:',
        img: commercialImg,
        imgAlt : 'Commercial law'
    },
    {
        name: "banking_and_finance",
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, minus!',
        routeName: "banking-and-finance",
        list: ['exp-bank-1', 'exp-bank-2', 'exp-bank-3', 'exp-bank-4'],
        listTitle: 'Our services include:',
        img: bankingImg,
        imgAlt : 'Banking and Finance'
    },
    {
        name: "tax_law",
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, minus!',
        routeName: "tax-law",
        list: ['exp-tax-1','exp-tax-2','exp-tax-3'],
        listTitle: 'Our services include:',
        img: complianceImg,
        imgAlt : 'Compliance'
    },
    {
        name: "real_estate",
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, minus!',
        routeName: "real-estate",
        list: ['exp-rest-1','exp-rest-2','exp-rest-3'],
        listTitle: 'Our services include:',
        img: realEstateImg,
        imgAlt : 'Real Estate law'
    },
    {
        name: "competition_law",
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, minus!',
        routeName: "competition-law",
        list: ['exp-comp-1','exp-comp-2','exp-comp-3','exp-comp-4','exp-comp-5','exp-comp-6',],
        listTitle: 'Our services include:',
        img: competitionImg,
        imgAlt : 'Competition law'
    },
    {
        name: "employment_law",
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, minus!',
        routeName: "employment-law",
        list: ['exp-emp-1','exp-emp-2','exp-emp-3'],
        listTitle: 'Our services include:',
        img: employmentImg,
        imgAlt : 'Employment law'
    },
    {
        name: "immigration_law",
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, minus!',
        routeName: "immigration-law",
        list: ['exp-immg-1','exp-immg-2','exp-immg-3'],
        listTitle: 'Our services include:',
        img: corporateImg,
        imgAlt : 'Corporate law and M&A'
    },
    {
        name: "intellectual_law",
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, minus!',
        routeName: "intellectual-property",
        list: ['exp-intel-1','exp-intel-2','exp-intel-3','exp-intel-4'],
        listTitle: 'Our services include:',
        img: intellectualImg,
        imgAlt : 'Intellectual property law'
    },
    {
        name: "dispute_resolution",
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, minus!',
        routeName: "dispute-resolution",
        list: ['exp-disp-1','exp-disp-2','exp-disp-3'],
        listTitle: 'Our services include:',
        img: disputeImg,
        imgAlt : 'Dispute resolution'
    },
]

export const industries = [
    {
        name: 'it_industry',
        routeName: 'it-industry',
        desc : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium eaque error quidem aliquid, quas velit. Earum itaque necessitatibus cupiditate temporibus!',
        list: ['ind-it-1','ind-it-2','ind-it-3'],
        listTitle: 'Our services include:',
        img: codeImg,
        imgAlt : 'IT'
    },
    {
        name: 'sports_industry',
        routeName: 'sports-industry',
        desc : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium eaque error quidem aliquid, quas velit. Earum itaque necessitatibus cupiditate temporibus!',
        list: ['ind-sport-1','ind-sport-2','ind-sport-3'],
        listTitle: 'Our services include:',
        img: sportImg,
        imgAlt : 'Sports'
    },
    {
        name: 'hospitality_industry',
        routeName: 'hospitality',
        desc : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium eaque error quidem aliquid, quas velit. Earum itaque necessitatibus cupiditate temporibus!',
        list: ['ind-hosp-1','ind-hosp-2','ind-hosp-3','ind-hosp-4'],
        listTitle: 'Our services include:',
        img: hospitalityImg,
        imgAlt : 'Hospitality'
    },
    {
        name: 'agro_industry',
        routeName: 'agriculture-industry',
        desc : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium eaque error quidem aliquid, quas velit. Earum itaque necessitatibus cupiditate temporibus!',
        list: ['ind-agro-1','ind-agro-2','ind-agro-3','ind-agro-4','ind-agro-5'],
        listTitle: 'Our services include:',
        img: agroImg,
        imgAlt : 'Agriculture'
    },
    {
        name: 'construction_industry',
        routeName: 'construction-industry',
        desc : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium eaque error quidem aliquid, quas velit. Earum itaque necessitatibus cupiditate temporibus!',
        list: ['ind-const-1','ind-const-2','ind-const-3','ind-const-4'],
        listTitle: 'Our services include:',
        img: constructionImg,
        imgAlt : 'Construction'
    },
]

export const languages = [
    {
        code: 'de',
        name: 'Deutsch',
        country_code: 'de'
    },
    {
        code: 'en',
        name: 'English',
        country_code: 'gb'
    },
    {
        code: 'rs',
        name: 'Srbija',
        country_code: 'rs'
    }
]

export const people = [
    {
      url: 'goran-korac',
      name: 'Goran',
      lastName: 'Korać',
      degree: 'LLB',
      role: 'goran-role',
      telephone: '+381 (0) 21 3035849',
      mobile: '+381 (0) 64 2538315',
      email: 'goran.korac@klaw.rs',
      areasOfExpertise : ['commercial_law', 'corporate_law' ,'business_law', 'tax_law', 'real_estate', 'employment_law','intellectual_law'],
      education: ['faculty_llb'],
      languages: ['lang-eng', 'lang-serbian'],
      image: goranImg,
      linkedin: 'https://www.linkedin.com/in/goran-kora%C4%87-6b012344/'
    },
    {
        url: 'teodora-cordarov',
        name: 'Teodora',
        lastName: 'Čordarov',
        degree: 'PhD Candidate',
        role: 'teodora-role',
        telephone: '+381 (0) 21 3035849',
        mobile: '+381 (0) 63 8326792 ',
        email: ' teodora.cordarov@klaw.rs',
        areasOfExpertise : ['it_law','cyber_law','intellectual_law', 'Fintech', 'Corporate Law', 'corporate_law', 'White Collar Crime'],
        education: ['tea-phd-ns', 'tea-llm-ns', 'tea-llb-ns', 'tea-llb-london'],
        languages: ['lang-eng', 'lang-spanish', 'lang-serbian'],
        image: teodoraImg,
        linkedin: 'https://www.linkedin.com/in/teodora-cordarov/'
    },
    {
        url: 'milana-vujin',
        name: 'Milana',
        lastName: 'Vujin',
        degree: 'LLM',
        role: 'milana-role',
        telephone: '+381 (0) 21 3035849',
        mobile: '+381 (0) 64 0174762',
        email: ' milana.vujin@klaw.rs',
        areasOfExpertise : ['commercial_law', 'tax_law', 'real_estate', 'competition_law'],
        education: ['faculty_llm-cand' , 'faculty_llb'],
        languages: ['lang-eng', 'lang-german', 'lang-serbian'],
        image: milanaImg,
        linkedin: 'https://www.linkedin.com/in/milana-vujin-353562258/'
    }
  ]