import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from '../../../../theme/theme';

export const StyledHeaderLogo = styled(Link)`
  width: 200px;

  img{
    width: 100%;
    height: 100%;
    object-fit:cover;
  }

  @media(max-width: ${theme.breakpoints.md}){
    width: 160px;
  }

  @media(max-width: ${theme.breakpoints.sm}){
    width: 140px;
  }
`;