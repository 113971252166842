import { motion } from "framer-motion";
import { Outlet, useLocation } from "react-router-dom";

export const AnimationLayout = () => {
    const PageLayout = ({ children }) => children;

    const { pathname } = useLocation();
    return (
      <PageLayout>
        <motion.div
          key={pathname}
          initial={{ x: -30, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 30, opacity: 0 }}
          transition={{ duration: .6, ease: "easeInOut", delay: 0.25}}
        >
          <Outlet />
        </motion.div>
      </PageLayout>
    );
  };