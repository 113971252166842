import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const StyledPersonItem = styled.li`
    width:50%;
    list-style-type: none;
    padding: 20px 0;
    position:relative;
    display:flex;
    align-items: center;
    justify-content: center;

    @media(max-width: ${theme.breakpoints.md}){
        width: 100%;

        &:not(:last-child) {
        &::after {
            content: '';
            position: absolute;
            top: unset;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            height: 2px;
            width: 60%;
            background: #BEBEBE;
        }
    }
    }

    @media(max-width: ${theme.breakpoints.sm}){
        &:not(:last-child) {
        &::after {
            width: 80%;
        }
    }
    }

    @media(max-width: ${theme.breakpoints.xs}){
        &:not(:last-child) {
        &::after {
            width: 100%;
        }
    }
    }
`


export const StyledPersonImgHolder = styled.div`
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    aspect-ratio: 9/12;

    @media(max-width: ${theme.breakpoints.lg}){
        width: calc(100% - 20px);
    }

    @media(max-width: ${theme.breakpoints.md}){
        width: 60%;
    }

    @media(max-width: ${theme.breakpoints.sm}){
        width: 80%;
    }

    @media(max-width: ${theme.breakpoints.xs}){
        width: 100%;
    }

    &:hover {
        img {
            transform: scale(1.06);
        }
    }

    .img-overlay{
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 70%, rgba(0,45,75,0.6) 100%);
        position: absolute;
        top:0;
        left: 0;
        z-index: 2;
    }
`


export const StyledPersonImg = styled.img`
    width: 100%;
    height: auto;
    position: relative;
    top:0;
    left:0;
    z-index: 1;
    transition: transform .4s ease;
    max-width: 100%;
    aspect-ratio: 9/12;
`


export const StyledPersonLink = styled(Link)`
    display:flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #000;
    width: 100%;
    height: 100%;

    &:hover {
        ${StyledPersonImg} {
            filter:  grayscale(0%);
            -webkit-filter: grayscale(0); 
        }
    }
`

export const StyledCardHolder = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 16px 32px;
    text-align: left;
    
    h2 {
        color: ${theme.colors.white};
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 30px;
    }
    
    h4 {
        color: ${theme.colors.white};
        font-size: 16px;
        font-weight: 400;
    }
`