import React from 'react'
import Wrapper from '../UI/Wrapper/Wrapper'
import LinkButton from '../UI/LinkButton/LinkButton'
import cottonWave from '../../assets/images/cotton-waves.webp'
import { StyledTeamBgImage, StyledTeamSection, StyledTeamSectionDesc, StyledTeamSectionHeading, StyledTeamWrap } from './TeamSection.styles';
import { t } from 'i18next';

const TeamSection = () => {
  return (
    <StyledTeamSection>
        <Wrapper>
            <StyledTeamWrap>
                <StyledTeamBgImage src={cottonWave} alt="background pattern" />
                <StyledTeamSectionHeading>{t('home-team_title')}</StyledTeamSectionHeading>
                <StyledTeamSectionDesc>{t('home-team_desc')}</StyledTeamSectionDesc>
                <LinkButton routerLink text={t('home-team_link')} title='our team' linkTo={'/team'}/>
            </StyledTeamWrap>
        </Wrapper>
    </StyledTeamSection>
  )
}

export default TeamSection