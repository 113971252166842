import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const ExpertiseHolder = styled.section`
    background: #E9E5E3;
    padding: 80px 110px;

    @media(max-width: ${theme.breakpoints.lg}){ 
        padding: 60px 40px;
    }
    @media(max-width: ${theme.breakpoints.sm}){ 
        padding: 40px 20px;
    }
`

export const ExpertisesContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    /* align-items: center; */
    justify-content: center;
`

export const ExpertiseHover = styled.div`
        position: absolute;
        top: 100%;
        left: -100%;
        background: ${theme.colors.blue};
        width: 100%;
        height: 100%;
        transition-property: top, left;
        transition: .6s ease-in-out;
        display:flex;
        align-items: center;
        justify-content: center;
`

export const StyledExpertiseItem = styled.div`
    width: calc(33.33% - 20px);
    padding: 35px 55px;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover {
        ${ExpertiseHover} {
            top: 0;
            left: 0;
        }
    }

    @media(max-width: ${theme.breakpoints.md}){ 
        width: calc(50% - 15px);
    }

    @media(max-width: ${theme.breakpoints.sm}){ 
        width: 100%;
    } 
`

export const StyledExpertiseItemHeading = styled.h3`
`

export const ExpertisesTop = styled.div`
    margin-bottom: 60px;
    text-align: center;

     @media (max-width: ${theme.breakpoints.sm}) {
        margin-bottom: 30px;
    }
`

export const ExpertisesTopHeading = styled.h1`
    margin-bottom: 30px;
`

export const ExpertisesText = styled.p`
    line-height: 200% !important;
    text-align: justify;
`