import React from 'react'
import bannerImg from '../../assets/images/blue-bg1-1.webp'
import { StyledBannerHeading, StyledBannerImgHolder, StyledBannerParagraph, StyledBannerText, StyledBannerWrap, StyledHomeBanner } from './HomeBanner.styles'
import LinkButton from '../UI/LinkButton/LinkButton'
import { useTranslation } from 'react-i18next'

const HomeBanner = () => {
  const {t} = useTranslation();
  return (
    <StyledHomeBanner>
        <StyledBannerWrap>
                <StyledBannerImgHolder>
                    <img src={bannerImg} alt="business banner" title='business banner' />
                    <div className='banner-overlay'></div>
                </StyledBannerImgHolder>
                <StyledBannerText>
                    <StyledBannerHeading>{t('banner_title')}</StyledBannerHeading>
                    <StyledBannerParagraph>{t('banner_text')}</StyledBannerParagraph>
                    <LinkButton linkTo={'/contact'} text={t('banner_link')} routerLink title='Link to expertise page'/>
                </StyledBannerText>
        </StyledBannerWrap>
    </StyledHomeBanner>
  )
}

export default HomeBanner