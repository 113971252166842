import React from 'react'
import logo from '../../../../assets/images/Korac-Logo.png'
import { StyledHeaderLogo } from './HeaderLogo.styles'

const HeaderLogo = () => {
  return (
    <StyledHeaderLogo to='/'>
        <img src={logo} alt="logo" title='klaw logo image'/>
    </StyledHeaderLogo>
  )
}

export default HeaderLogo