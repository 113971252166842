import React from 'react'
import Header from '../../components/shared/Header/Header'
import Footer from '../../components/shared/Footer/Footer'
import { useNavigate, useParams } from 'react-router-dom'
import {industries } from '../../utils/Data'
import Wrapper from '../../components/UI/Wrapper/Wrapper'
import { StlyedListTitle, StyledIndDesc, StyledIndImg, StyledIndListItem, StyledIndText, StyledIndTitle, StyledIndWrap } from './IndustryPage.styles'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next'


const IndustryPage = () => {
  const {t} = useTranslation();
  const {name} = useParams();
  const [ind] = industries.filter(ind => ind.routeName === name)
  const navigate = useNavigate();
  if(!ind) {
    navigate('/error')
  } 
  return (
    <>
      <Helmet>
      <title>{t(`${ind?.name}`)} | {`${t('expertise-page_title')}` }</title>
      <meta name="description" content={`${t(`${ind?.name}`)} | ${t('expertise-page_desc')} : ${t(`${ind?.name}`)}`} />
      <meta name="author" content="Petar Purać" />
      <link rel="canonical" href={`https://klaw.rs/industries/${name}`} />
    </Helmet>
    <div>
    <Header></Header>
    <main className='main'>
        <Wrapper>
          <StyledIndWrap>
            <StyledIndText>
              <StyledIndTitle>{t(`${ind?.name}`)}</StyledIndTitle>
              <div>
                <ul>
                  {ind?.list?.map(item => (
                    <StyledIndListItem>
                    {t(item)}
                  </StyledIndListItem>))}
                </ul>
              </div>
            </StyledIndText>
            <StyledIndImg>
              <img src={ind?.img} alt={ind?.imgAlt} title='image that fits industry'/>
            </StyledIndImg>
          </StyledIndWrap>
        </Wrapper>
      </main>
    <Footer></Footer>
  </div>
  </>
  )
}

export default IndustryPage