import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const StyledHomeBanner = styled.section`
    position: relative;
    width: 100%;
    height: 70vh;
    margin-bottom: 60px;
    @media (max-width: ${theme.breakpoints.md}) {
        margin-bottom: 40px;
    }

    @media(max-width: ${theme.breakpoints.md}){
      height: auto;
    }
`

export const StyledBannerImgHolder = styled.div`
    position: absolute;
    top:0;
    left: 0;
    width: calc(100% - 40px);
    margin-left: 20px;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transform: rotate(180deg);
    }

    .banner-overlay {
        width: 100%;
        height: 100%;
        background: rgba(0, 45, 75, .6);
        position: absolute;
        top:0;
        left: 0;
        z-index: 2;
    }

    @media(max-width: ${theme.breakpoints.md}){
        position: relative;
        height: auto;
        width: 100%;
        margin-left: 0;
        margin-bottom: 20px;

        img {
            object-position: top;
        }
    }
`

export const StyledBannerWrap = styled.div `
    width: 100%;
    height: 100%;
    max-width: 1540px;
    padding: 0 20px;
    margin: 0 auto;
    position: relative;
    display:flex;
    align-items:center;
    justify-content:flex-end;

    @media(max-width: ${theme.breakpoints.md}){
        flex-direction: column;
    }
`

export const StyledBannerText = styled.div`
    position: relative;
    z-index: 2;
    padding: 50px 30px;
    background: rgba(255,255,255, .8);
    max-width: 65%;
    width: 100%;
    margin-right: 20px;

    @media(max-width: ${theme.breakpoints.lg}){
        width: 75%;
        max-width: 100%;
    }

    @media(max-width: ${theme.breakpoints.md}){
        width: 100%;
        background:${theme.colors.lightGrey};
        margin-right: 0;
    }

    @media(max-width: ${theme.breakpoints.md}){
        padding: 0;
    }
    
`

export const StyledBannerHeading = styled.h1`
    color: ${theme.colors.blue};
    margin-bottom: 30px;
`

export const StyledBannerParagraph = styled.p`
    color: ${theme.colors.blue};
    margin-bottom: 30px;
`