import React from 'react'
import { StyledFooterInfo } from './FooterInfo.styles'
import { useTranslation } from 'react-i18next';


const FooterInfo = () => {
  const {t} = useTranslation();
  const footerData = [' +381 (0) 21 3035849', '+381 (0) 64 2538315','office@klaw.rs', `© 2023, ${t('footer-copy')}`]
  return (
    <StyledFooterInfo>
        {footerData.map(item => (
            <div key={item}>{item}</div>
        ))}
    </StyledFooterInfo>
  )
}

export default FooterInfo