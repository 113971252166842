import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const StyledTeamMemberPage = styled.section`
    background: #EDE9E7; 
    padding: 60px;

    @media(max-width: ${theme.breakpoints.lg}){
        padding: 40px;
    }

    @media(max-width: ${theme.breakpoints.md}){
        padding: 20px 0;
    }
`

export const TeamMemberPageHolder = styled.div`
    background: #fff;
    padding: 40px;
    display: flex;
    gap: 20px;
    align-items: flex-start;

    @media(max-width: ${theme.breakpoints.lg}){
        padding: 30px;
    }

    @media(max-width: ${theme.breakpoints.md}){
        flex-direction: column;
        margin: 20px 0;
    }

    @media(max-width: ${theme.breakpoints.sm}){
        gap: 0;
    }
`

export const TeamMemberLeft = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    @media(max-width: ${theme.breakpoints.md}){
        width: 100%;
    }
`

export const TeamMemberRight = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    @media(max-width: ${theme.breakpoints.md}){
        align-items: flex-start;
    }
`

export const TeamMemberImgHolder = styled.div`
    max-width: 500px;
    overflow: hidden;
    margin-bottom: 60px;
    aspect-ratio: 9/12;

    @media(max-width: ${theme.breakpoints.md}){
        margin-bottom: 0;
        height: 450px;
        aspect-ratio: 9/12;

    }

    @media(max-width: ${theme.breakpoints.xs}){
        height: 380px;
        max-width: 320px;
        width: 100%;
        aspect-ratio: 9/12;
    }
`

export const TeamMemberImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`
export const TeamMemberLogoImg = styled.img`
    filter: grayscale(100%);
`

export const TeamMemberRightInfo = styled.div`
    border-bottom: 1px solid; 
    padding: 30px;
    text-align: left;
    @media(max-width: ${theme.breakpoints.md}){
        width: 100%;
        padding: 20px 0;
    }
`

export const TeamMemberName = styled.h1`
    margin-bottom: 20px;
`

export const TeamMemberRole = styled.h2`
    margin-bottom: 20px;
`

export const TeamMemberContact = styled.p`
    margin-bottom: 10px;
`

export const TeamMemberRightStats = styled.div`
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    @media(max-width: ${theme.breakpoints.md}){
       padding: 20px 0;
    }
`

export const TeamMemberStat = styled.div`
    width: 50%; 
    text-align: left;

    @media(max-width: ${theme.breakpoints.md}){
        width: 100%;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
`
export const TeamMemberStatTitle = styled.h5`
    margin-bottom: 10px;
`

export const TeamMemberStatItem = styled.p`
    font-size: 16px;
    margin-bottom: 5px;
`

export const TeamMemberLogoHolder = styled.div`
    @media(max-width: ${theme.breakpoints.md}){
        display: none;
    }
`