import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {theme} from '../../../../theme/theme.jsx'

export const StyledNavItem = styled.li`
    align-self: stretch;
    padding: 0 20px;
    list-style-type: none;
    display: flex;
    border-right: 1px solid ${theme.colors.blue}3a;
    &:last-child {
        padding-right: 0;
        border-right: 0
    }
    @media only screen and (max-width: ${theme.breakpoints.lg}){
        padding: 0 15px;
    }
    @media only screen and (max-width: ${theme.breakpoints.md}) {
       justify-content: center;
       border-right: none;
       &:last-child {
        padding: 0 20px;
       }
    }
    `;

export const StyledNavLink = styled(Link)`
    text-decoration: none;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 60px;
    align-self: stretch;
    color: ${theme.colors.blue};
    transition: color .4s ease;
    font-weight: 600;

    &.active {
        color: ${theme.colors.red};
    }

    &:hover {
        color: ${theme.colors.red};
    }
    @media only screen and (max-width: ${theme.breakpoints.md}) {
        color: ${theme.colors.white};
    }
`;