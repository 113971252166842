import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const StyledInternational = styled.div`
    width: 66.66%;

    @media (max-width: ${theme.breakpoints.lg}) {
        width: 50%;
    }

    @media (max-width: ${theme.breakpoints.md}) {
        width: 100%;
        padding: 0;
    }
`

export const StyledInternationalHolder = styled.div`
    border: 2px solid ${theme.colors.blue};
    padding: 40px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    position: relative;
    color: ${theme.colors.white};
`

export const StyledInternationalHeading = styled.h2`
        margin-bottom: 40px;
        color: ${theme.colors.white} !important;

         @media (max-width: ${theme.breakpoints.sm}) {
        margin-bottom: 20px;
    }
`

export const StyledInternationalDesc = styled.p`
        text-align:center;
        color: ${theme.colors.white} !important;
        margin-bottom: 30px;

         @media (max-width: ${theme.breakpoints.sm}) {
        margin-bottom: 20px;
    }
`

export const StyledInternationalImg = styled.img`
    width: 100%;
    height: 100%; 
    position: absolute;
    z-index: -1;
`