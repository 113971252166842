import React from 'react'
import logo from '../../../../assets/images/klaw-logo-white2.png'
import { StyledFooterLogo } from './FooterLogo.styles'

const FooterLogo = () => {
  return (
    <StyledFooterLogo to='/'>
        <img src={logo} alt="logo" title='klaw logo image'/>
    </StyledFooterLogo>
  )
}

export default FooterLogo


