import React from 'react'
import Wrapper from '../UI/Wrapper/Wrapper'
import AboutUs from '../AboutUs/AboutUs'
import International from '../International/International'
import { StyledAboutSection, StyledAboutSectionHolder } from './AboutSection.styles'

const AboutSection = () => {
  return (
    <StyledAboutSection>
        <Wrapper>
            <StyledAboutSectionHolder>
                <AboutUs/>
                <International/>
            </StyledAboutSectionHolder>
        </Wrapper>
    </StyledAboutSection>
  )
}

export default AboutSection