import React from 'react'
import Wrapper from '../UI/Wrapper/Wrapper'
import LinkButton from '../UI/LinkButton/LinkButton'
import bgImg from '../../assets/images/abstract-luxury-gradient-blue-background-smooth-dark-blue-with-black-vignette.webp'
import { IndustriesBot, IndustriesBotList, IndustriesListItem, IndustriesListLink, IndustriesSectionHolder, IndustriesSectionImg, IndustriesTop, IndustriesTopDesc, IndustriesTopHeading, StyledIndustriesSection } from './IndustriesSection.styles'
import { industries } from '../../utils/Data'
import { useTranslation } from 'react-i18next'


const IndustriesSection = () => {
    const {t} = useTranslation();
  return (
    <StyledIndustriesSection>
        <Wrapper>
            <IndustriesSectionHolder>
                <IndustriesSectionImg src={bgImg} alt="industries background" title="bussiness background for industries section"/>
                <IndustriesTop>
                    <IndustriesTopHeading>{t('home-industries_title')}</IndustriesTopHeading>
                    <IndustriesTopDesc>{t('home-industries_text')}</IndustriesTopDesc>
                    <LinkButton text={t('home-industries_link')} linkTo='/industries' routerLink iswhite={true} title='Our industries'/>
                </IndustriesTop>
                <IndustriesBot>
                    <IndustriesBotList>
                        {industries.map(industry => (
                            <IndustriesListItem key={industry.routeName}>
                            <IndustriesListLink to={`/industries/${industry.routeName}`}>{t(`${industry.name}`)}</IndustriesListLink>
                        </IndustriesListItem>
                        ))}
                    </IndustriesBotList>
                </IndustriesBot>
            </IndustriesSectionHolder>
        </Wrapper>
    </StyledIndustriesSection>
  )
}

export default IndustriesSection