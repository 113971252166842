import styled from 'styled-components';


export const StyledHeader = styled.header`
  width: 100%;
  padding: 20px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 999;

    &.header-sticky {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

  @media only screen and (min-width: 993px) {
    .hamburger-react {
      display: none;
    }
  }
`;

export const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  .hamburger-react {
    z-index: 99;
  }
`;

