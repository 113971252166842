import React from 'react'
import Wrapper from '../UI/Wrapper/Wrapper'
import ContactInfo from '../ContactInfo/ContactInfo';
import ContactMap from '../ContactMap/ContactMap';
import { StyledContactModule, StyledContactWrapper } from './ContactModule.styles';

const ContactModule = () => {
   
  return (
    <StyledContactModule>
        <Wrapper>
            <StyledContactWrapper>
                <ContactMap />
                <ContactInfo />
            </StyledContactWrapper>
        </Wrapper>
    </StyledContactModule>
  )
}

export default ContactModule