import styled from 'styled-components';

export const StyledErrorPage = styled.section`
` 

export const StyledErrorPageHolder = styled.section`
    padding: 60px;
    border: 2px solid;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items:center;
`

export const StyledErrorHeading = styled.h1`
    margin-bottom: 40px;
`

export const StyledErrorDesc = styled.p`
    margin-bottom: 20px;
`