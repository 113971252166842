import React from 'react'
import Wrapper from '../UI/Wrapper/Wrapper'
import { StyledAPA, StyledAPAHeading, StyledAPAHolder, StyledAPAIconHolder, StyledAPALeft, StyledAPARight, StyledAPARightHeading, StyledAPAdesc } from './AboutPageAbout.styles'
import handshakeIcon from '../../assets/icons/handshake-icon3.svg'
import LinkButton from '../UI/LinkButton/LinkButton'
import TextIcon from '../TextIcon/TextIcon'
import diligenceIcon from '../../assets/icons/diligence-icon2.svg'
import communicationIcon from '../../assets/icons/communication-icon.svg'
import { useTranslation } from 'react-i18next'

const AboutPageAbout = () => {
    const {t} = useTranslation();
  return (
    <StyledAPA>
        <Wrapper>
            <StyledAPAHolder>
                <StyledAPALeft>
                    <StyledAPAHeading>{t('about-page_about-title')}</StyledAPAHeading>
                    <StyledAPAdesc>{t('about-page_about-desc')}</StyledAPAdesc>
                    <LinkButton linkTo='/contact' hashLink iswhite={false} text={t('about-page_about-link')} title='Contact us'></LinkButton>
                </StyledAPALeft>
                <StyledAPARight>
                    <StyledAPARightHeading>{t('about-page_icon-title')}</StyledAPARightHeading>
                    <StyledAPAIconHolder>
                        <TextIcon icon={handshakeIcon} text={t('about-page_icon-integrity')}/>
                        <TextIcon icon={communicationIcon} text={t('about-page_icon-communication')}/>
                        <TextIcon icon={diligenceIcon} text={t('about-page_icon-diligence')}/>
                        <div style={{position: 'absolute', visibility: 'hidden'}}> Handshake by Chrystina Angeline from <a href="https://thenounproject.com/browse/icons/term/handshake/" target="_blank" title="Handshake Icons" rel="noreferrer">Noun Project</a></div>
                        <div style={{position: 'absolute', visibility: 'hidden'}}><a style={{display: 'none'}} href="https://thenounproject.com/browse/icons/term/business/" target="_blank" title="Business Icons" rel="noreferrer">Noun Project</a></div>
                        <div style={{position: 'absolute', visibility: 'hidden'}}><a href="https://thenounproject.com/browse/icons/term/business-communication/" target="_blank" title="Business communication Icons" rel="noreferrer">Noun Project</a></div>
                    </StyledAPAIconHolder>
                </StyledAPARight>
            </StyledAPAHolder>
        </Wrapper>
    </StyledAPA>
  )
}

export default AboutPageAbout