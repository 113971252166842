import styled from 'styled-components';
import { theme } from '../../theme/theme';
import { Link } from 'react-router-dom';

export const StyledExpertisesSection = styled.section`
    margin-bottom: 60px;
    @media (max-width: ${theme.breakpoints.md}) {
        margin-bottom: 40px;
    }
`

export const StyledExpertisesHolder = styled.div`
    width: 100%;
    
    width: 100%;
    height:100%;
    position: relative;
    border: 2px solid ${theme.colors.blue};
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`

export const ExpertiseTop = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 60px 40px;

     @media (max-width: ${theme.breakpoints.sm}) {
        padding: 40px;
    }
`

export const ExpertiseTopHeading = styled.h2`
    margin-bottom: 40px;

     @media (max-width: ${theme.breakpoints.sm}) {
        margin-bottom: 20px;
    }
`

export const ExpertiseTopDesc = styled.p`
    margin-bottom: 30px;

     @media (max-width: ${theme.breakpoints.sm}) {
        margin-bottom: 20px;
    }
`

export const ExpertiseBot = styled.div`
    width: 100%;
`

export const ExpertiseBotList = styled.ul`
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    border-top: 2px solid ${theme.colors.blue};

`

export const ExpertiseListItem = styled.li`
    list-style-type: none;
    width: 33.33%;

    &:nth-child(-n + 6) {
        border-bottom: 2px solid ${theme.colors.blue}
    }
    
    &:not(:nth-child(3n)) {
        border-right: 2px solid ${theme.colors.blue}
    }

    @media(max-width : ${theme.breakpoints.sm}){
        width: 50%;

        &:nth-child(-n + 6) {
            border-bottom: none;
        }
        
        &:not(:nth-child(3n)) {
            border-right: none;
        }

        &:nth-child(-n + 8) {
            border-bottom: 2px solid ${theme.colors.blue}
        }

        &:not(:nth-child(2n)) {
            border-right: 2px solid ${theme.colors.blue}
        }

        &:nth-child(9) {
            width: 100%;
            border-right: none;
        }

    }

    @media(max-width : ${theme.breakpoints.xs}){
        width: 100%;
        border-right: none !important;
    }
`

export const ExpertiseListLink = styled(Link)`
    width: 100%;
    height: 100%;
    display: block;
    padding: 40px 30px;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    color: ${theme.colors.blue};
    font-size: 18px;
    font-weight: 600;
    transition: color .6s ease;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background: ${theme.colors.blue};
        z-index: -1;
        transition: width .6s ease;
    }

    &:hover {
        color: ${theme.colors.white};

        &::before {
            width: 100%;
        }
    }

    @media (max-width: ${theme.breakpoints.md}) {
       font-size: 16px;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
       padding: 20px;
    }
`