import React from 'react'
import LinkButton from '../UI/LinkButton/LinkButton'
import { StyledAboutDesc, StyledAboutHeader, StyledAboutHolder, StyledAboutUs } from './AboutUs.styles'
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
  const {t} = useTranslation();
  return (
    <StyledAboutUs>
        <StyledAboutHolder>
            <StyledAboutHeader>{t('home-about_title')}</StyledAboutHeader>
            <StyledAboutDesc>{t('home-about_desc')}</StyledAboutDesc>
            <LinkButton linkTo='/about' hashLink iswhite={false} text={t('home-about_link')} title='Learn about us'></LinkButton>
        </StyledAboutHolder>
    </StyledAboutUs>
  )
}

export default AboutUs