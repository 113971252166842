import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const AboutInternationalSection = styled.section`
     margin-bottom: 80px;

    @media(max-width: ${theme.breakpoints.md}){
        margin-bottom: 40px;
    }
    @media(max-width: ${theme.breakpoints.sm}){
        margin-bottom: 30px;
    }
`

export const APInternationalLeft = styled.div`
    position: relative;
    width: 33.33%;
    
    .img-overlay{
        width: 100%;
        height: 100%;
        background: rgba(0, 45, 75, .6);
        position: absolute;
        top:0;
        left: 0;
        z-index: 2;
    }
    
    @media(max-width: ${theme.breakpoints.md}){
        width: 100%;
        height: 250px;
    }
`
export const AboutInternationalHolder = styled.div`
    width: 100%;
    display:flex;
    align-items: stretch;
    gap: 20px;

    @media(max-width: ${theme.breakpoints.md}){
        flex-direction: column;
    }
`
export const AboutInternationalImage = styled.img`
    width: 100%; 
    height: 100%; 
    object-fit: cover;
`
export const AboutInternationalText = styled.div`
    width: 66.66%;
    background:  #E9E5E3;
    padding: 60px 80px;

    @media(max-width: ${theme.breakpoints.md}){
        width: 100%;
        padding: 30px 40px;
    }
`

export const AboutInternationalHeading = styled.h1`
    margin-bottom: 40px;
    font-size: 36px;
    line-height: 44px;
    @media(max-width: ${theme.breakpoints.md}){
        font-size: 28px !important;
        line-height: 36px !important;
    }
    @media(max-width: ${theme.breakpoints.sm}){
        font-size: 24px !important;
        line-height: 32px !important;
    }
`

export const AboutInternationalDesc = styled.p`
    margin-bottom: 15px;
`