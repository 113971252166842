import styled from 'styled-components';
import { theme } from '../../../../theme/theme';

export const StyledNav = styled.nav`
    z-index: 98;
    display:flex;
    align-items: center;
    gap: 20px;
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      position: fixed;
      top: 0;
      right: 0;
      width: 40%;
      height: 100%;
      background: ${theme.colors.blue};
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateX(100%);
      transition: transform .4s ease;
      flex-direction: column;
      max-height: 100%;
      overflow-y: scroll;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      width: 50%;
    }

    @media only screen and (max-width: ${theme.breakpoints.xs}) {
      width: 70%;
    }
  &.active {
    transform: translateX(0);
  }
`;

export const StyledNavList = styled.ul`
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
  }
`;

export const NavLanguages = styled.div`
  position: relative;
`

export const LanguagesDropdown = styled.div`
  position: absolute;
  top: 110%;
  left: -50%;
  display:flex;
  flex-direction: column;
  padding: 10px 20px;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.blue};

  @media(max-width: ${theme.breakpoints.md}) {
    left: -25%;
  }
`

export const LanguageBtn = styled.button`
  background: none;
  border: none;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  display:flex;
  gap: 15px;
  align-items: center;
  color: ${theme.colors.blue};
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &:not(.active) {
    opacity: .3;
  }
`

export const LanguageBtnFlag = styled.span`
  display:block;
  width: 25px;
  height: 25px;
`

export const GlobeBtn = styled.button`
  background: none;
  border: none;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  @media(max-width: ${theme.breakpoints.md}) {
    color: #fff;
    svg {
        path {
          fill: #fff !important;
        }
      }

  }
`

export const GlobeBtnText = styled.span`
  display: flex;
`