import React, { useEffect, useState } from 'react'
import Wrapper from '../../UI/Wrapper/Wrapper'
import HeaderLogo from './HeaderLogo/HeaderLogo'
import HeaderNav from './HeaderNav/HeaderNav'
import { HeaderWrap, StyledHeader } from './Header.styles'
import { Squeeze as Hamburger } from 'hamburger-react'

const Header = () => {
  const [isOpen, setOpen] = useState(false)
  const [headerSticky, setHeaderSticky] = useState(false);
  const [setActiveItem] = useState();

  const handle = () => {
    setOpen(prev => !prev)
  }

  const activeItemHandler = (item) => {
    setActiveItem(item)
  }
  

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setHeaderSticky(true);
      } else {
        setHeaderSticky(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  

  return (
    <StyledHeader  className={`${headerSticky ? 'header-sticky' : ''}`}>
      <Wrapper>
        <HeaderWrap>
          <HeaderLogo />
          <HeaderNav isOpen={isOpen} passActiveItem={activeItemHandler}/>
          <Hamburger color={isOpen ? '#fff' : '#002D4B' } toggled={isOpen} toggle={setOpen} onClick={handle} label="Show menu" className='burger'/>
        </HeaderWrap>
      </Wrapper>
    </StyledHeader>
  )
}

export default Header