import React from 'react'
import { StyledInternational, StyledInternationalDesc, StyledInternationalHeading, StyledInternationalHolder, StyledInternationalImg } from './International.styles'
import LinkButton from '../UI/LinkButton/LinkButton'
import bgImg from '../../assets/images/blue-bg-banner.webp'
import { useTranslation } from 'react-i18next'

const International = () => {
  const {t} = useTranslation();
  return (
    <StyledInternational>
        <StyledInternationalHolder>
            <StyledInternationalImg src={bgImg} alt="business background" title='business background for international section'/>
            <StyledInternationalHeading>{t('home-inter_title')}</StyledInternationalHeading>
            <StyledInternationalDesc>{t('home-inter_desc')}</StyledInternationalDesc>
            <LinkButton linkTo='/about#bb' iswhite={true} hashLink text={t('home-inter_link')} title='To International Desk'></LinkButton>
        </StyledInternationalHolder>
    </StyledInternational>
  )
}

export default International