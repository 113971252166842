import React from 'react'
import  { languages, navigationLinks } from '../../../../utils/Data';
import NavItem from '../NavItem/NavItem';
import { GlobeBtn, GlobeBtnText, LanguageBtn, LanguageBtnFlag, LanguagesDropdown, NavLanguages, StyledNav, StyledNavList } from './HeaderNav.styles';
import { useState } from 'react';
import Icon from '../../../Icon/Icon';
import { theme } from '../../../../theme/theme';
import globeIcon from '../../../../assets/icons/globe.svg'
import i18next from 'i18next';
import chevronDown from '../../../../assets/icons/dropdown-svgrepo-com.svg'
import { useTranslation } from 'react-i18next';


const HeaderNav = ({isOpen, passActiveItem}) => {
  const [isLangVisible, setIsLangVisible] = useState();
  const {t} = useTranslation();

  const handleGlobeClick = () => {
    setIsLangVisible(prev => !prev)
  }

  const handleLanguageClick = (code) => {
    i18next.changeLanguage(code);
    setIsLangVisible(prev => !prev);
  }

  return (
    <StyledNav className={isOpen ? 'active' : ''}>
        <StyledNavList>
            {navigationLinks.map((item, index) => (
                <NavItem key={item.id} name={t(`${item.name}`)} id={item.id} linkTo={item.linkTo} title={item.title} index={index}/>
            )) }
        </StyledNavList>
        <NavLanguages>
            <GlobeBtn onClick={handleGlobeClick}>
                <Icon icon={globeIcon} className='globe-btn' color={theme.colors.blue} height={40} width={40} />
                <GlobeBtnText>
                  <Icon icon={chevronDown} color={theme.colors.red} height={15} width={15} />
                </GlobeBtnText>
            </GlobeBtn>
           {isLangVisible && 
           <LanguagesDropdown>
            {languages.map(({code, name, country_code}) => (
                <LanguageBtn key={code} onClick={() => handleLanguageClick(code)} className={i18next.language === code ? 'active' : ''}>
                    <LanguageBtnFlag className={`fi fi-${country_code}`}></LanguageBtnFlag>
                    {code}
                </LanguageBtn>
            ))}
            </LanguagesDropdown>}
        </NavLanguages>
    </StyledNav>
  )
}

export default HeaderNav