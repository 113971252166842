import React from 'react'
import { useParams } from "react-router-dom";
import Wrapper from '../../components/UI/Wrapper/Wrapper';
import { StyledTeamMemberPage, TeamMemberContact, TeamMemberImg, TeamMemberImgHolder, TeamMemberLeft, TeamMemberLogoHolder, TeamMemberLogoImg, TeamMemberName, TeamMemberPageHolder, TeamMemberRight, TeamMemberRightInfo, TeamMemberRightStats, TeamMemberRole, TeamMemberStat, TeamMemberStatItem, TeamMemberStatTitle } from './TeamMemberPage.styles';
import logo from '../../assets/images/logo.png'
import Header from '../../components/shared/Header/Header';
import Footer from '../../components/shared/Footer/Footer';
import { theme } from '../../theme/theme';
import linkedinIcon from '../../assets/icons/linkedin-rounded-border-svgrepo-com.svg'
import Icon from '../../components/Icon/Icon';
import { people } from '../../utils/Data';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const TeamMemberPage = () => {
    const {url} = useParams();
    const [prsn] = people.filter(person => person.url === url);
    const {t} = useTranslation();
  return (
    <>
    <Helmet>
      <title>{t('team-page_title')}</title>
      <meta name="description" content={t('team-page_desc')}/>
      <meta name="author" content="Petar Purać" />
      <link rel="canonical" href={`https://klaw.rs/team/${url}`} />
    </Helmet>
      <Header></Header>
      <main className='main'>
        <StyledTeamMemberPage>
            <Wrapper>
                <TeamMemberPageHolder>
                    <TeamMemberLeft>
                        <TeamMemberImgHolder>
                            <TeamMemberImg src={prsn.image} alt="team member" title='image of team member'/>
                        </TeamMemberImgHolder>
                        <TeamMemberLogoHolder>
                            <TeamMemberLogoImg src={logo} alt="logo klaw" title='Korać logo'/>
                        </TeamMemberLogoHolder>
                    </TeamMemberLeft>
                    <TeamMemberRight>
                        <TeamMemberRightInfo>
                            <TeamMemberName>{prsn.name} {prsn.lastName}</TeamMemberName>
                            <TeamMemberRole>{t(prsn.role)}</TeamMemberRole>
                            <TeamMemberContact><b>T:</b> {prsn.telephone}</TeamMemberContact>
                            <TeamMemberContact><b>M:</b> {prsn.mobile}</TeamMemberContact>
                            <TeamMemberContact><b>E:</b> {prsn.email}</TeamMemberContact>
                            <a href={prsn.linkedin} target='_blank' rel="noreferrer">
                              <Icon icon={linkedinIcon} className='globe-btn' color={theme.colors.blue} height={30} width={30} />
                            </a>
                        </TeamMemberRightInfo>
                        <TeamMemberRightStats>
                            <TeamMemberStat>
                                <TeamMemberStatTitle>{t('areas-of-exp')}:</TeamMemberStatTitle>
                            {prsn.areasOfExpertise.map(area => (
                              <TeamMemberStatItem key={area}>{t(area)}</TeamMemberStatItem>
                                ))}
                            </TeamMemberStat>
                            <TeamMemberStat>
                              <TeamMemberStatTitle>{t('education')}:</TeamMemberStatTitle>
                            {prsn.education.map(edc => (
                              <TeamMemberStatItem key={edc}>{t(edc)}</TeamMemberStatItem>
                              ))}
                            </TeamMemberStat>
                            <TeamMemberStat>
                            <TeamMemberStatTitle>{t('languages')}:</TeamMemberStatTitle>
                            {(prsn.languages).map(lng => (
                              <TeamMemberStatItem key={lng}>{t(lng)}</TeamMemberStatItem>
                              ))}
                            </TeamMemberStat>
                        </TeamMemberRightStats>
                    </TeamMemberRight>
                </TeamMemberPageHolder>
            </Wrapper>
        </StyledTeamMemberPage>
      </main>
      <Footer></Footer>
    </>
  )
}

export default TeamMemberPage