import styled from 'styled-components';
import { theme } from '../../../theme/theme';

export const StyledFooter = styled.footer`
    background: ${theme.colors.blue};
    padding: 30px 0;
    margin-top: 10px;
`;

export const StyledFooterWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
`;