import React from 'react'
import { StyledContactMap } from './ContactMap.styles';

const ContactMap = () => {
    const mapSrc = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2808.8213439969736!2d19.841917415747755!3d45.25140535563762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475b106bb8cce17b%3A0xb2c5c434e6bcd07c!2z0JLQsNGB0LUg0KHRgtCw0ZjQuNGb0LAgMTQsINCd0L7QstC4INCh0LDQtCAyMTAwMA!5e0!3m2!1ssr!2srs!4v1674510504303!5m2!1ssr!2srs';
  return (
    <StyledContactMap>
        <iframe title='location map' src={mapSrc} width="600" height="450" loading="eager" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </StyledContactMap>
  )
}

export default ContactMap
