import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const StyledTeamSection = styled.section`
    width: 100%;
    margin-bottom: 60px;
    @media (max-width: ${theme.breakpoints.md}) {
        margin-bottom: 40px;
    }
`

export const StyledTeamWrap = styled.div`
    width: 100%;
    position: relative;
    padding: 60px 40px;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    text-align: center;

     @media (max-width: ${theme.breakpoints.sm}) {
        padding: 40px;
    }
`
export const StyledTeamBgImage = styled.img`
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        object-fit: cover;
`

export const StyledTeamSectionHeading = styled.h2`
    margin-bottom: 40px;
    @media (max-width: ${theme.breakpoints.sm}) {
        margin-bottom: 20px;
    }
`

export const StyledTeamSectionDesc = styled.p`
    margin-bottom: 30px;
    @media (max-width: ${theme.breakpoints.sm}) {
        margin-bottom: 20px;
    }
`