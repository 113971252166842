import React from 'react'
import LinkButton from '../../components/UI/LinkButton/LinkButton'
import Wrapper from '../../components/UI/Wrapper/Wrapper'
import { StyledErrorDesc, StyledErrorHeading, StyledErrorPage, StyledErrorPageHolder } from './ErrorPage.styles'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const ErrorPage = () => {
  const {t} = useTranslation();
  return (
    <>
   <Helmet>
      <title>{t('error-page_title')}</title>
      <meta name="description" content={t('error-page_desc')}/>
      <meta name="author" content="Petar Purać" />
    </Helmet>
    <StyledErrorPage>
      <main className="main">
        <Wrapper>
          <StyledErrorPageHolder>
            <StyledErrorHeading>Oops! <br /> 404 - Page Not Found</StyledErrorHeading>
            <StyledErrorDesc>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</StyledErrorDesc>
            <LinkButton linkTo='/' hashLink iswhite={false} text='Go To Homepage' title='Go to homepage'></LinkButton>
          </StyledErrorPageHolder>
        </Wrapper>
      </main>
    </StyledErrorPage>
    </>
  )
}

export default ErrorPage