import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const StyledAboutSection = styled.section`
    margin-bottom: 60px;
    @media (max-width: ${theme.breakpoints.md}) {
        margin-bottom: 40px;
    }
`

export const StyledAboutSectionHolder = styled.div`
    display: flex;
    gap: 20px;
    align-items: stretch;

    @media (max-width: ${theme.breakpoints.md}) {
        flex-direction: column;
    }
`