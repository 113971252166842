import React from 'react'
import Wrapper from '../UI/Wrapper/Wrapper'

import LinkButton from '../UI/LinkButton/LinkButton'
import { ExpertiseBot, ExpertiseBotList, ExpertiseListItem, ExpertiseListLink, ExpertiseTop, ExpertiseTopDesc, ExpertiseTopHeading, StyledExpertisesHolder, StyledExpertisesSection } from './ExpertisesSection.styles'
import { expertises } from '../../utils/Data'
import { useTranslation } from 'react-i18next'

const ExpertisesSection = () => {
    const {t} = useTranslation();
  return (
    <StyledExpertisesSection>
        <Wrapper>
            <StyledExpertisesHolder>
                <ExpertiseTop>
                    <ExpertiseTopHeading>{t('home-expertise_title')}</ExpertiseTopHeading>
                    <ExpertiseTopDesc>{t('home-expertise_text')}</ExpertiseTopDesc>
                    <LinkButton text={t('home-expertise_link')} linkTo='/expertises' routerLink iswhite={false} title='Our Expertises' />
                </ExpertiseTop>
                <ExpertiseBot>
                    <ExpertiseBotList>
                        {expertises.map(expertise => (
                            <ExpertiseListItem key={expertise.routeName}>
                               <ExpertiseListLink to={`/expertises/${expertise.routeName}`}>{t(`${expertise.name}`)}</ExpertiseListLink>
                           </ExpertiseListItem>
                        ))}
                    </ExpertiseBotList>
                </ExpertiseBot>
            </StyledExpertisesHolder>
        </Wrapper>
    </StyledExpertisesSection>
  )
}

export default ExpertisesSection