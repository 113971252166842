import styled from 'styled-components';
import { theme } from '../../../theme/theme';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export const StyledLinkButton = styled.a`
    display:flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    width: fit-content;
    padding: 7px 0 5px;
    transition: .4s ease;
    transition-property: background;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        height: 2px;
        width: 0;
        background: ${theme.colors.blue};
        transition: .4s ease;
        transition-delay: .2s;
        transition-property: width;
    }

    &.link--white {
        &::before {
            content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        height: 2px;
        width: 0;
        transition: .4s ease;
        transition-delay: .2s;
        transition-property: width;
            background: ${theme.colors.white};
        }
    }
    
    span {
        color: ${theme.colors.red};
        font-size: 18px;
        font-weight: 600;
    }

    .link-icon {
        position: absolute;
        right: -25px;
        top: 50%;
        transform: translateY(-50%);
        transition: .4s ease;
        transition-property: right;
    }

    &:hover {
        .link-icon {
            right: -30px;
        }
        
        &::before {
            width: calc(100% + 30px);
        }
    }
`
export const StyledRouterLinkButton = styled(Link)`
    display:flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    width: fit-content;
    padding: 7px 0 5px;
    transition: .4s ease;
    transition-property: background;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        height: 2px;
        width: 0;
        background: ${theme.colors.blue};
        transition: .4s ease;
        transition-delay: .2s;
        transition-property: width;
    }

    &.link--white {
        &::before {
            content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        height: 2px;
        width: 0;
        transition: .4s ease;
        transition-delay: .2s;
        transition-property: width;
            background: ${theme.colors.white};
        }
    }
    
    span {
        color: ${theme.colors.red};
        font-size: 18px;
        font-weight: 600;
    }

    .link-icon {
        position: absolute;
        right: -25px;
        top: 50%;
        transform: translateY(-50%);
        transition: .4s ease;
        transition-property: right;
    }

    &:hover {
        .link-icon {
            right: -30px;
        }

        &::before {
            width: calc(100% + 30px);
        }
    }
`

export const StyledHashLinkButton = styled(HashLink)`
    display:flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    width: fit-content;
    padding: 7px 0 5px;
    transition: .4s ease;
    transition-property: background;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        height: 2px;
        width: 0;
        background: ${theme.colors.blue};
        transition: .4s ease;
        transition-delay: .2s;
        transition-property: width;
    }

    &.link--white {
        &::before {
            content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        height: 2px;
        width: 0;
        transition: .4s ease;
        transition-delay: .2s;
        transition-property: width;
            background: ${theme.colors.white};
        }
    }
    
    span {
        color: ${theme.colors.red};
        font-size: 18px;
        font-weight: 600;
    }

    .link-icon {
        position: absolute;
        right: -25px;
        top: 50%;
        transform: translateY(-50%);
        transition: .4s ease;
        transition-property: right;
    }

    &:hover {
        .link-icon {
            right: -30px;
        }

        &::before {
            width: calc(100% + 30px);
        }
    }
`