import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const StyledExpWrap = styled.div`
    display:flex;
    width: 100%;
    gap: 5%;
    padding: 40px 0;
    min-height: calc(100vh - 244px);
    @media(max-width: ${theme.breakpoints.md}){ 
        flex-direction: column;
    }
`

export const StyledExpImg = styled.div`
    width: 33.33%;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media(max-width: ${theme.breakpoints.md}){ 
        width: 100%;
        height: 300px;
    }
    @media(max-width: ${theme.breakpoints.sm}){ 
        height: 200px;
    }
`

export const StyledExpText = styled.div`
    width: 66.66%;
    padding: 80px 110px;
    border: 2px solid ${theme.colors.blue};

    @media(max-width: ${theme.breakpoints.lg}){ 
        padding: 60px 40px;
    }
    @media(max-width: ${theme.breakpoints.md}){ 
        width: 100%;
        order: 2;
    }
    @media(max-width: ${theme.breakpoints.sm}){ 
        padding: 40px 20px;
    }
`

export const StyledExpTitle = styled.h1`
    margin-bottom: 40px;
`

export const StyledExpDesc = styled.p`
    margin-bottom: 40px;
`

export const StlyedListTitle = styled.h2`
    margin-bottom: 30px;
`

export const StyledExpListItem = styled.li`
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 15px;
`