import styled from "styled-components";



export const StyledIcon = styled.div`
    width: ${({width}) =>  width}px;
    height: ${({height}) =>  height}px;

    svg {
        width: 100%;
        height:100%;
        path {
            fill: ${({color}) => color}
        }
    }
`